import React from 'react';
import { Button, Box } from '@mui/material';
import { useHistory, Link } from 'react-router-dom';
import "./Home.css";

function Home({ textLoaded, setTextLoaded, addressAPI, callbackStarted, callbackTextYourChoice,
    callbackTextFAQ, callbackTextTCsTitles, callbackTextTCsContent,
    colourActive, colourDisabledDark }) {

    const history = useHistory();



    const handleStartClick = () => {    
        callbackStarted(true)
        history.push("about you")
    };

    const handleFAQClick = () => {
        history.push("faq")
    };

    const handleTandCClick = () => {
        history.push("terms and conditions")
    };

    return (
        <div className="home__body">
            <div className="home__container">
                <div className="home__text__container">
                    <p className="home__text">

                    The Scottish Teachers' Pension Scheme Retirement Calculator is designed to help illustrate the projected benefits members will receive 
                    at different retirement ages from both the legacy and reformed Teachers' Pension Schemes in Scotland.

                    </p>  
                    <p className="home__text">
                    This calculator is for guidance purposes only and the outputs provided are an estimate only. It is not 
                    intended to provide you with financial advice. If you require financial advice, you should contact an independent financial adviser. 
                    </p>   

                    {/* <p>** 2006 Scheme members are currently unable to use this version of the calculator. A version which includes the 2006 Scheme is in development and will be released shortly **</p> */}
                    {/* <br></br> */}
                    <p className="home__text"><strong>Legacy Schemes</strong></p>

                    <p className="home__text">
                    Teachers who joined before 1 April 2007 will have joined the teachers NPA 60 Pension Scheme (NPA 60 Scheme) unless they opted 
                    not to do so. The new teachers NPA 65 Pension Scheme (NPA 65 Scheme) came into effect for new entrants from 1 April 2007.   

                    </p>
                    <p className="home__text">
                    The NPA 60 and NPA 65 Schemes are ‘final salary’ schemes, which means that pensions are calculated as a proportion 
                    of final pensionable pay. This is generally pay in the last year of service as a member of the scheme. The pension 
                    also depends on reckonable service, which for most teachers will be the length of service over 
                    which they have paid pension contributions, with appropriate adjustments for part-time service. 
                    </p>  
                    <p className="home__text">
                     The NPA 60 and NPA 65 Schemes are referred to as the “legacy” schemes throughout this calculator. Further details 
                     about the legacy schemes can be found in the <a href="https://pensions.gov.scot/teachers">scheme guides</a>.
                    </p> 


                    <p className="home__text"><strong>Reformed Scheme</strong></p>

                    <p className="home__text">
                    The Scottish Teachers' Pension Scheme 2015 (2015 Scheme) came into effect for new entrants on 1 April 2015. Some members 
                    of the legacy schemes had ‘transitional protection’ that meant they stayed in their legacy scheme, while 
                    others moved to the 2015 Scheme on or after 1 April 2015. However, it was announced in February 2021 that 
                    most members of the legacy schemes will receive a ‘deferred choice’ of whether to receive legacy or 2015 Scheme 
                    benefits for the period 1 April 2015 to 31 March 2022. See the <Link to="/faq">FAQ section</Link> for information 
                    on who is eligible and why they have this choice. 
                    </p>
                    <p className="home__text">

                    From 1 April 2022 all teachers who continue in service will do so as members of the 2015 Scheme, regardless of 
                    age. The 2015 scheme is a Career Average Revalued Earnings (CARE) pension scheme. This means that for each year 
                    as an active member teachers will earn a fraction (1/57) of the salary for that year as earned pension and this 
                    will be revalued for each subsequent year until retirement. Further details about the 2015 Scheme can be found 
                    in the <a href="https://pensions.gov.scot/teachers/about-teachers-pensions/scottish-teachers-pension-scheme-2015">2015 Scheme guide</a>.
                    </p>

                    <p className="home__text"><strong>Illustrations provided in this calculator</strong></p>

                    <p className="home__text">
                    The illustrations in this calculator draw out estimates of the pension and lump sum that may be available at 
                    various scheme milestones where relevant. 
                    </p>
                    <p className="home__text">
                    You are also able to see an estimate of your projected deferred benefits if you leave the scheme prior to being 
                    eligible for retirement.
                    </p>
                    <p className="home__text">
                    At retirement some members will be able to choose between receiving legacy or reformed benefits for their 
                    pensionable service between 1 April 2015 and 31 March 2022. This model is designed to help illustrate the
                    differences in benefits members may receive depending on the choice they make. 
                    </p>
                    <p className="home__text">
                    The following information will be provided in the relevant results sections:
                    <ul>
                        <li>"Your Summary” provides a simple illustration of benefits at different retirement ages with a choice on whether legacy or reformed benefits are accrued for 2015-22.</li>
                        <li>"Your Choice” – “Comparison Chart” provides a more detailed view of the above comparison, illustrating how your pension under each retirement age varies over time (again, with a choice on whether legacy or reformed benefits are accrued for 2015-22).</li>
                        <li>"Your Choice” – Individual scenario charts (e.g. “Retirement at age 60”) – provide a detailed comparison of benefits under both options – legacy or reformed benefits accrued during 2015-22.</li>
                    </ul>
                    </p>
                    <p className="home__text">
                    If you require help with understanding the illustrations in this calculator then please refer to 
                    the <Link to="/faq">FAQ section</Link> or the <a href="https://pensions.gov.scot/teachers">scheme guides</a>. 
                    If you have any further questions, please contact SPPA.
                    </p>
                    <p className="home__text">
                    The estimated pension and lump sum amounts at retirement have been estimated using a given set of assumptions. 
                    Using different assumptions could produce materially different results. The key assumptions used are future 
                    inflation and salary increases.  
                    </p>

                    <p className="home__text"><strong>How to use this calculator </strong></p>

                    <p className="home__text">
                    Please click “Start” at the bottom of this page to fill in the “About You” section. 
                    </p>
                    <p className="home__text">
                    Information including the date you first joined the scheme and pensionable earnings is required for this section. 
                    You should ensure that accurate information is entered.    
                    </p>
                    <p className="home__text">
                     If you require help with filling in the "About You” section, please refer to your most recent annual benefit 
                     statement. If you do not have access to this then please register for the Member Self Service portal where this 
                     information is available.  You can do this at:  <a href= "https://memberportal.pensions.gov.scot/home/">https://memberportal.pensions.gov.scot/home/</a>.   
                    </p>
                    <p className="home__text">
                    By using this calculator you agree you have read and understood the Terms & Conditions as set out in 
                    the <Link to="/terms and conditions">'T&Cs' tab</Link>.
                    </p>

                    <p className="home__text"><strong>Getting further help </strong></p>

                    <p className="home__text">
                    If you have any issues with the calculator not working as expected, please refer to the <Link to="/faq">FAQ section</Link>.
                    </p>
                    <p className="home__text">
                    The following link provides access to member guides which detail the range of benefits to which you may be entitled 
                    to as a member of the Teachers' Pension Schemes: <a href="https://pensions.gov.scot/teachers">https://pensions.gov.scot/teachers</a>
                    </p>
                    <p className="home__text">
                    This calculator is designed and configured to be used on a desktop computer and so use via a mobile phone or other 
                    device may not provide an optimal user experience.
                    </p>

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button variant="outlined" sx={{ mr: 1 }} style={{color:colourDisabledDark,border:colourDisabledDark,borderStyle:'solid',borderWidth:'1px'}} onClick={handleFAQClick}> Go to FAQ </Button>
                        <Button variant="outlined" sx={{ mr: 1 }} style={{color:colourDisabledDark,border:colourDisabledDark,borderStyle:'solid',borderWidth:'1px'}} onClick={handleTandCClick}> Go to T&Cs </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button variant="outlined" style={{color:colourActive,border:colourActive,borderStyle:'solid',borderWidth:'1px'}} onClick={handleStartClick}>Start</Button>
                    </Box>
                    <br></br>

                </div>

            </div>
        </div>
    )
}

export default Home
